
function Contact(){
  return(
    <div className="static top-0 sm:text-3xl text-sky-950 bg-slate-50 h-full min-h-screen w-full flex flex-col justify-center drop-shadow-2xl items-center font-bold  text-center">
    <div className="pb-10 max-sm:text-2xl">Get in touch</div>
    <ul>
    <li className="underline">

    PEOPLE THAT CARE
    </li>

    <li>
    P.O. Box 857
    </li>

    <li>
    Lineville AL 36366

    </li>
    ralphmoore1940@gmail.com
    <li>
    
    
    </li>
    <li>
    (256)396-0188    
    </li>


    </ul>
    </div>
  )
}
export default Contact;
